import React from 'react';
import { Helmet } from 'rnd-helmet';
import { injectIntl } from 'react-intl';
import HeaderText from '@UI/HeaderText';
import BlogFilter from '@Filters/Blog';
import SortBar from '@Components/SortBar';
import Pager from '@Components/Pager';
import Breadcrumbs from '@Components/Breadcrumbs';
import NoResults from '@Components/NoResults';
import Block from '@UI/Block';
import Optimizely from '@TrackingComponents/Optimizely';
import { useRouteMatch } from 'react-router-dom';
import GetRouteFromUrl from 'GetRouteFromUrl';
import ArticleList from '@UI/ArticleOverview/List';
import { getCurrentCategoryName } from 'facetsHelper';
import handleFirstPageRedirect from 'handleFirstPageRedirect';
import handleUnknownParams from 'handleUnknownParams';
import handleStaticContext from 'handleStaticContext';
import capitalizeFirstLetter from 'capitalizeFirstLetter';
import getCanonicalLink from 'getCanonicalLink';
import appendPageToMetaTitle from 'appendPageToMetaTitle';
import getCareerCategorySpecificMeta from 'getCareerCategorySpecificMeta';
import withSearch from '../../components/hoc/Search';

function Career({
  intl, searchParams, pager, articles, total, canonicalOverrides, staticContext,
}) {
  const { locale } = intl;
  const route = useRouteMatch();
  const routeConfig = GetRouteFromUrl();
  const baseUrl = route.path.replace(':searchParams*', '');
  const pageType = 'career';

  if (searchParams && searchParams.page && parseInt(searchParams.page) === 1) {
    return handleFirstPageRedirect(baseUrl, searchParams, pageType);
  }

  if (searchParams && searchParams.unknown) {
    return handleUnknownParams();
  }

  handleStaticContext(staticContext, locale);

  let metaTitle = intl.formatMessage({ id: 'Career.Title' }, { sitename: process.env.REACT_APP_SITENAME });
  let metaDescription = intl.messages['Career.MetaDescription'] || null;
  let titleTop = intl.formatMessage({ id: 'CareerHeader.TitleTop' });
  let titleBottom = intl.messages['CareerHeader.TitleBottom'] || null;

  const categoryName = searchParams.facets ? getCurrentCategoryName(searchParams.facets) : '';

  if (categoryName) {
    const updatedMeta = getCareerCategorySpecificMeta(categoryName, intl, metaTitle, metaDescription, titleTop);
    metaTitle = updatedMeta.metaTitle;
    metaDescription = updatedMeta.metaDescription;
    titleTop = updatedMeta.titleTop;

    titleTop = intl.messages['CareerHeader.CategoryTitleTop']
      ? intl.formatMessage({ id: 'CareerHeader.CategoryTitleTop' }, { category: categoryName })
      : titleTop;
    titleBottom = intl.messages['CareerHeader.CategoryTitleTopBottom']
      ? intl.formatMessage({ id: 'CareerHeader.CategoryTitleTopBottom' })
      : titleBottom;
  }

  metaTitle = appendPageToMetaTitle(capitalizeFirstLetter(metaTitle), intl, searchParams.page);
  const canonicalLink = getCanonicalLink(baseUrl, searchParams, canonicalOverrides, pageType);

  return (
    <div id="articles-search-listing">
      <Optimizely />
      <Helmet>
        <title>{metaTitle}</title>
        {metaDescription ? <meta name="description" content={metaDescription} /> : null}
        <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}${canonicalLink}`} />
      </Helmet>
      <Breadcrumbs breadcrumbs={routeConfig?.breadcrumbs} currentRoute={route} searchParams={searchParams} pageType="career" />
      <HeaderText
        bgColor="blue"
        titleTop={titleTop}
        titleBottom={titleBottom}
      >
        {intl.messages['CareerHeader.text'] && intl.formatMessage({ id: 'CareerHeader.text' })}
      </HeaderText>
      <SortBar resultsTotal={total} searchParams={searchParams} />
      <Block beforeContent={<BlogFilter pageType="career" searchParams={searchParams} resultsTotal={total} />} align="right" contentSize="s" type="filter">
        <ArticleList>
          {total > 0 ? articles : <NoResults />}
        </ArticleList>
        <Pager previous={pager.previous} pagesList={pager.pages} next={pager.next} />
      </Block>
    </div>
  );
}

export default injectIntl(withSearch(Career));
